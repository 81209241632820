import React from 'react';
import Category from './Category'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBrain } from '@fortawesome/free-solid-svg-icons'

function Main(props) {
    return (
        <>
            <Info />

            <div className="row mt-4">
                {
                    props.categories.map((item) => {
                        return (<Category info={item} key={item.name} />)
                    }
                    )}
            </div>
        </>
    );
}

function Info(props) {
    return (

        <header className="shadow-soft border-light p-4 rounded" >
            <div className="overlay"></div>

            <video playsinline="playsInLine" autoplay="autoplay" muted="muted" loop="loop">
                <source src="./gradient.mp4" type="video/mp4" />
            </video>

            <div className="container h-100">
                <div className="d-flex h-100 text-center align-items-center">
                    <div className="w-100 text-white">
                        <h1 className="display-6"><FontAwesomeIcon icon={faBrain} /> Alpha Brainwaves</h1>
                        <hr />
                        <p><b>Instructions:</b> Headphones required. Choose a track. Find a safe and quiet space where you can listen and relax in peace. </p>
                        <p>Close your eyes and let your mind quiet down. </p>
                        <p className="lead">
                            <Link className="btn btn-primary btn-lg" to={'page/terms'}>Read the Terms an Conditions for usage</Link>
                        </p>
                    </div>
                </div>
            </div>
        

        </header >

    );
}

export default Main;



