import React from 'react';
import {
  Card, CardImg, CardText, CardBody, CardFooter,
  CardTitle, CardSubtitle, Button
} from 'reactstrap';
import { Link } from 'react-router-dom';
const Category = (props) => {
  const info = props.info;
  return (

    <div className="col-12 col-sm-6 col-lg-3">
      <div className="card bg-primary border-light shadow-soft">
        <Link to={`tracklist/${info.name}`}  ><img className="card-img-top rounded-top" src={info.thumbnail} alt="Card image cap" /></Link>
        <div className="card-body">
          <h3 className="font-weight-bold font-italic text-capitalize h5 card-title">{info.name}</h3>
          <p className="" style={{ minHeight: "3em" }} >{info.description}</p>
        </div>
        <CardFooter className="card-text">
          <Link to={`tracklist/${info.name}`} className="btn-block btn btn-primary mb-3 mb-lg-0 mr-3">Help me {info.name}</Link>
        </CardFooter>
      </div>
    </div>

  );
};



export default Category;