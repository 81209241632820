import React from 'react';
import {
    Card, CardImg, CardText, CardHeader, CardBody, Breadcrumb, BreadcrumbItem,
    CardTitle, CardSubtitle, Button
} from 'reactstrap';


import {
    useParams, Link
} from "react-router-dom";

const Track = (props) => {
    const info = props.info;
    return (
        <div className="col-12 col-md">
            <Card className="border-light shadow-soft">
                <CardHeader className="font-weight-bold">
                    {props.title}
                </CardHeader>
                <CardBody>
                    <CardText><b>Duration:</b> {props.duration}</CardText>
                    <Link to={`/play/${props.name}`} className="btn btn-block btn-primary btn-lg">Select</Link>
                </CardBody>
            </Card>
        </div>

    );
};

function TrackList(props) {
    let { categoryId } = useParams();

    const tracklist = props.tracks.filter((item) => { return item.category === categoryId }).map(
        item => { return <Track {...item} key={item.name} /> }
    )

    return (
        <>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-transparent breadcrumb-dark">
                    <BreadcrumbItem><Link to={`/`} className="">Home</Link></BreadcrumbItem>
                    <BreadcrumbItem active className="text-capitalize">{categoryId}</BreadcrumbItem>
                </ol>
            </nav>

            <div className="container-fluid">
                <h2 className="text-capitalize">{categoryId}</h2>
            </div>
            <div className="row mt-4">
                {tracklist}
            </div>
        </>
    )
}
export default TrackList;

