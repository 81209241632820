import React from 'react';
import { Link } from 'react-router-dom';

function Footer(props) {

    return (
        <footer className=" border-light border-top text-center text-lg-start pt-0 mt-auto">
        {/* <!-- Grid container --> */}
        <div className="container p-4">
          {/* <!--Grid row--> */}
          <div className="row footer-links">

            
            <div className="col mb-md-0">
              <ul className="list-unstyled mb-0">
                <li>
                  <a href="https://alphabrain.joyfulweb.net" className="text-dark">alphabrain.joyfulweb.net</a>
                </li>
              </ul>
            </div>
            <div className="col mb-4 mb-md-0">
              <ul className="list-unstyled mb-0">
                <li>
                <Link className="text-dark" to={'/page/terms'}>Terms of Use</Link>
                </li>
              </ul>
            </div>
 

          </div>
        </div>
     
      
      
        <div className="text-center p-3 bg-light" style={{xbackgroundColor: "rgba(0, 0, 0, 0.2)"}}>
          © 2021 Copyright:
          <a className="text-dark" href=""> Alpha Brainwaves</a>
        </div>
  
      </footer>
    )
}

export default Footer;