import React, { useState } from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBrain } from '@fortawesome/free-solid-svg-icons'

const MyNavBar = (props) => {
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <>
      <Navbar className="px-0 ">
        <NavbarBrand href="/" className="mr-auto py-2 mr-lg-4"><FontAwesomeIcon icon={faBrain} /> Alpha Brainwaves</NavbarBrand>
        {/* <NavbarToggler onClick={toggleNavbar} className="mr-2" />
        <Collapse isOpen={!collapsed} navbar>
          <Nav navbar>
            <NavItem>
              <NavLink href="/pages/terms/">Components</NavLink>
            </NavItem>
         
          </Nav>
        </Collapse> */}
      </Navbar>
    </>
  );
}

export default MyNavBar;
