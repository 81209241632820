import React, { useState, useEffect } from 'react';
import './App.css';
// import { Button } from 'reactstrap';
import AudioPlayer from './AudioPlayer'
import { HashRouter as Router, Route, Link, Switch } from 'react-router-dom';
import Main from './Main'
import TrackList from './TrackList'
import NavBar from './NavBar'
import Footer from './Footer'
import data from '../data.json'


import Page from './Page'

const log = console.log

class App extends React.Component {
  constructor() {
    super();
    this.state = { isLoading: true };
  }

  componentDidMount() {
    this.setState({ categories: data.categories, tracks: data.tracks, currentTrack: null, isLoading: false });
  }

  render() {
    if (this.state.isLoading) {
      return (<h1>Loading...</h1>)
    }

    return (
      <>

        <Router basename={process.env.PUBLIC_URL + '/'} >
          <main className="flex-shrink-0 container">
            <NavBar />
            <Switch>
              <Route
                exact path="/tracklist/:categoryId"
                render={(props) => { return (<TrackList tracks={this.state.tracks} {...props} />) }}
              />
              <Route
                exact path="/play/:trackId"
                render={(props) => { return (<AudioPlayer tracks={this.state.tracks} {...props} />) }}
              />
              <Route
                exact path="/page/:pageId"
                render={(props) => { return (<Page pageId={props.match.params.pageId} {...props} />) }}
              />
              <Route
                exact path="/"
                render={(props) => { return (<Main {...props} categories={this.state.categories} />) }}
              />
            </Switch>
          </main>
          <Footer />
        </Router>
      </>
    );
  }
}

export default App;