import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown'
const log = console.log

class Page extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        log(this.props)
        // const pagename = 'terms'
        let path = require(`../content/${this.props.pageId}.md`);
        fetch(path).then(res => res.text()).then(text => { this.setState({ markdown: text }) });
    }

    render() {
        return (
            <div className="container">
                <ReactMarkdown source={this.state.markdown} />
            </div>
        )
    }
}

export default Page;
