import React from 'react';
import {
  Link
} from "react-router-dom";
import {
  ButtonGroup, Button, Card, CardImg, CardBody, CardTitle, CardSubtitle, CardText,
  Breadcrumb, BreadcrumbItem
} from 'reactstrap';

import soundwaveimg from '../assets/soundwaves.gif'


class AudioPlayer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      play: false
    }

    let { trackId } = this.props.match.params;
    this.trackId = trackId;
    this.currentTrack = this.props.tracks.find((item) => { return item.name === this.trackId })
  }
  componentDidMount() {
    this.audio = new Audio(this.currentTrack.uri);
    this.audio.addEventListener('ended', () => this.setState({ play: false }));
  }

  componentWillUnmount() {

    this.audio.removeEventListener('ended', () => this.setState({ play: false }));
    this.audio.pause()
    this.audio.currentTime = 0.0;
  }

  togglePlay = () => {
    console.log("d*", this.audio.duration, this.audio.currentTime)
    this.setState({ play: !this.state.play }, () => {
      this.state.play ? this.audio.play() : this.audio.pause();
    });
  }

  render() {
    if (!this.currentTrack) return (<div>Loading...</div>);
    return (
      <div className="row">
        <div className="col">

          <nav aria-label="breadcrumb">
            <ol className="breadcrumb breadcrumb-transparent breadcrumb-dark">
              <BreadcrumbItem><Link to={`/`} className="">Home</Link></BreadcrumbItem>
              <BreadcrumbItem><Link to={`/tracklist/${this.currentTrack.category}`} className="text-capitalize">{this.currentTrack.category}</Link></BreadcrumbItem>
              <BreadcrumbItem active className="text-capitalize">{this.currentTrack.title}</BreadcrumbItem>
            </ol>
          </nav>

          <Card className="shadow-soft border-light mx-auto " style={{ maxWidth: "600px" }}>
            <div className="card-header p-0 mt-3">
              <div style={{ width: '222px', height: '222px' }} className="mt-2 bg-dark mx-auto profile-image bg-primary shadow-inset border border-light rounded p-3 ml-3">
                {this.state.play &&

        
                    <CardImg style={{ width: '222px' }} src={soundwaveimg} alt="soundwave" className="rounded bg-dark card-img-top " />
        

                }
              </div>
            </div>
            <CardBody>
              <CardTitle className="text-capitalize font-weight-bold">{this.currentTrack.title}</CardTitle>
              <CardText>{this.currentTrack.duration}</CardText>
              {!this.state.play ?
                <button type="button" onClick={this.togglePlay} className="btn btn-lg btn-block btn-primary">

                  <svg className="bi bi-play" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M10.804 8L5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4å.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z" />
                  </svg>
                  &nbsp; Start</button>

                :
                <button type="button" onClick={this.togglePlay} className="btn btn-lg btn-block btn-primary">

                  <svg className="bi bi-pause" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M6 3.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5zm4 0a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5z" />å
                  </svg>
                  Pause
                </button>
              }
            </CardBody>
          </Card>
        </div>
      </div>
    );
  }
}

export default AudioPlayer;